<template>
  <div class="background">
    <div id="user-profile">
      <b-overlay :show="loading">
        <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
          <slot name="navbar">
            <profile-header />
          </slot>
        </b-navbar>
        <div class="page-header">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 col-md-7 col-sm-12 text-center text-md-left">
                <div class="page-caption ml-lg-3">
                  <p class="text-black" style="font-weight: bold; font-size: 3rem;">Temukan Peluang Belajar</p>
                  <p class="text-black font-weight-bold h4">Menarik dan Raih Tujuan Akademik Anda!</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-5 col-sm-12 text-center text-md-right">
                <img
                  src="https://cdn-web-2.ruangguru.com/landing-pages/assets/hs/LP%20Ruangguru%20Program/banner-right-program.png"
                  alt="Icon" class="header-image img-fluid mx-auto mx-md-0">
              </div>
            </div>
          </div>
        </div>

        <!-- news -->
        <div class="card-section">
          <div class="container" style="padding-left: 5vw; padding-right: 5vw; margin-bottom: 1.5rem;">
            <div class="card-block bg-white rounded border">
              <div class="section-title mb-3">
                <h2>Paket Paket Pembelajaran</h2>
                <p>Pilih Paket Bimbel & Try Out yang Tepat untuk Kesuksesanmu.</p>
              </div>
              <div class="py-4">
                <div class="container">
                  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
                    <div class="col" v-for="(item, i) in data" :key="i" @click="detail(item)">
                      <div class="card mx-auto shadow-lg rounded-3"
                        style="width: 100%; max-width: 20rem; transition: 0.3s;">
                        <div class="position-relative overflow-hidden rounded-top-3">
                          <img :src="`https://api-ecourse.digma.id/${item.thumbnail}`" class="card-img-top img-fluid"
                            alt="Thumbnail" style="height: 200px; object-fit: cover" />
                        </div>
                        <div class="card-body">
                          <div class="d-flex mb-2 font-weight-bold" style="font-size: 14px;">
                            <span class="text-black">{{ item.name }}</span>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <i class="fa-regular fa-user text-black"></i>
                            <span class="text-black ms-1">150</span>
                            <i class="fa-regular fa-clock text-black ml-4"></i>
                            <span class="text-black ms-1">40m</span>
                          </div>
                          <hr>
                          <div class="d-flex align-items-center">
                            <span class="text-black font-weight-bold">Rp {{ item.price.toLocaleString('id-ID') }}</span>
                            <span class="ml-auto">
                              <button type="button" class="btn btn-outline-primary btn-sm">Beli</button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer-home />
      </b-overlay>
    </div>
  </div>
</template>



<script>
  import {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
  } from "bootstrap-vue";

  import ProfileHeader from "./components/Navbar.vue";
  import FooterHome from "./components/Footer.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  /* eslint-disable global-require */
  export default {
    components: {
      BOverlay,
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      BButton,
      BLink,
      BBreadcrumb,
      BBreadcrumbItem,
      FooterHome,
      ProfileHeader,
    },
    data() {
      return {
        data: [],
        loading: false,
      };
    },

    methods: {
      getColor(color) {
        let warna = "";
        if (color == "primary") {
          warna = "blue";
        } else if (color == "success") {
          warna = "green";
        } else if (color == "danger") {
          warna = "red";
        } else if (color == "info") {
          warna = "light-blue";
        } else if (color == "warning") {
          warna = "yellow";
        }
        return warna;
      },
      getData() {
        this.loading = true;
        let params = {
          orderCol: "price",
          order: "asc",
        };
        this.$store
          .dispatch("paketTryout/index", params)
          .then((res) => {
            this.loading = false;
            this.data = res.data.data;
          })
          .catch((err) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      },
      detail(item) {
        window.location = `paket-detail/${item.slug}`;
      },
    },
    created() {
      this.getData();
    },
  };
  /* eslint-disable global-require */
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: 'Overpass', sans-serif;
    letter-spacing: 0px;
    font-size: 1rem;
    color: #8d8f90;
    font-weight: 400;
    line-height: 1.8;
    background-color: #edefef;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #25292a;
    margin: 0 0 10px 0;
    font-family: 'Overpass', sans-serif;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 1.2;
  }

  h1 {
    font-size: 2.125rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.375rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1.0625rem;
  }

  h6 {
    font-size: 0.75rem;
  }

  p {
    margin: 0 0 1.25rem;
    line-height: 1.7;
  }

  ul,
  ol {
    padding-left: 1.25rem;
  }

  a {
    text-decoration: none;
    color: #8d8f90;
    transition: all 0.3s ease;
  }

  a:focus,
  a:hover {
    color: #f85759;
  }

  .page-header {
    position: relative;
    background: linear-gradient(to bottom, #cce8ed, #d8eef2);
    background-size: cover;
    padding: 4rem 0;
    overflow: hidden;
  }

  .page-header::before,
  .page-header::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.8;
  }

  .page-header::before {
    width: 150px;
    height: 150px;
    top: 10%;
    left: 10%;
  }

  .page-header::after {
    width: 100px;
    height: 100px;
    bottom: 10%;
    right: 10%;
  }

  .page-caption {
    padding: 8rem 1rem;
    text-align: left;
  }

  .page-title {
    font-size: 2.875rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }

  .card-section {
    position: relative;
    bottom: 3.75rem;
  }

  .card-block {
    padding: 5rem 2rem;
  }

  .section-title {
    margin-bottom: 3.75rem;
  }

  .header-image {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 1200px) {
    .page-caption {
      padding: 6rem 1rem;
    }

    .card-block {
      padding: 4rem 1rem;
    }
  }

  @media (max-width: 768px) {
    .page-caption {
      padding: 4rem 1rem;
    }

    h1 {
      font-size: 1.875rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    .page-title {
      font-size: 2rem;
    }

    .header-image {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  @media (max-width: 576px) {
    .page-caption {
      padding: 3rem 0.5rem;
      text-align: center;
    }

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    .header-image {
      max-width: 60%;
      margin: 0 auto;
    }
  }
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "navbar navbar-expand-lg navbar-light sticky-navbar",
    attrs: {
      "id": "navbar"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('img', {
    attrs: {
      "src": "https://i.imgur.com/2Wfxivu.png",
      "width": "6%",
      "height": "5%",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "navbar-search"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })]), _c('button', {
    staticClass: "navbar-toggler",
    attrs: {
      "type": "button",
      "data-toggle": "collapse",
      "data-target": "#navbarNav",
      "aria-controls": "navbarNav",
      "aria-expanded": "false",
      "aria-label": "Toggle navigation"
    }
  }, [_c('span', {
    staticClass: "navbar-toggler-icon"
  })]), _c('div', {
    staticClass: "collapse navbar-collapse",
    attrs: {
      "id": "navbarNav"
    }
  }, [_c('ul', {
    staticClass: "navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item text-center"
  }, [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "home"
    }
  }, [_vm._v("Beranda")])]), _c('li', {
    staticClass: "nav-item text-center"
  }, [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "/paket-tryout"
    }
  }, [_vm._v("Kelas")])]), _c('li', {
    staticClass: "nav-item text-center"
  }, [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "/e-book"
    }
  }, [_vm._v("Artikel")])])]), _c('div', {
    staticClass: "navbar-btns ml-auto d-flex"
  }, [_c('a', {
    attrs: {
      "href": "/login"
    }
  }, [_c('button', {
    staticClass: "btn btn-masuk"
  }, [_vm._v("Masuk")])]), _c('a', {
    attrs: {
      "href": "/register"
    }
  }, [_c('button', {
    staticClass: "btn btn-register"
  }, [_vm._v("Daftar")])])])])])]);
}]

export { render, staticRenderFns }
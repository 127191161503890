<template>
  <nav class="navbar navbar-expand-lg navbar-light sticky-navbar" id="navbar">
    <div class="container">
      <!-- Logo -->
      <img src="https://i.imgur.com/2Wfxivu.png" width="6%" height="5%" alt="">

      <!-- Search Input -->
      <div class="navbar-search">
        <input type="text" class="form-control" placeholder="Search" />
      </div>

      <!-- Navbar Toggler for smaller screens -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <!-- Navigation Links -->
        <ul class="navbar-nav">
          <li class="nav-item text-center">
            <a class="nav-link" href="home">Beranda</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="/paket-tryout">Kelas</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="/e-book">Artikel</a>
          </li>
        </ul>

        <!-- Buttons (Login & Register) -->
        <div class="navbar-btns ml-auto d-flex">
          <a href="/login">
            <button class="btn btn-masuk">Masuk</button>
          </a>
          <a href="/register">
            <button class="btn btn-register">Daftar</button>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/images/logo/bsh.svg"),
    };
  },
};
</script>


<style scoped>
.navbar {
  padding: 0.1rem 8rem; /* Adjusted padding for layout */
  border-bottom: 2px solid white;
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.sticky-navbar.sticky {
  background-color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Center the navbar items */
.navbar-nav {
  display: flex;
  gap: 3rem;
  width: 100%;
  justify-content: center;
}

/* Navbar item styles */
.navbar-nav .nav-item .nav-link {
  font-weight: 600;
  color: #333333;
  position: relative;
  transition: color 0.3s ease;
}

.navbar-nav .nav-item:hover .nav-link {
  color: black;
}

/* Search input style */
.navbar-search {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  border-radius: 20%;
  width: 1px;
}

.navbar-search input {
  width: 100%;
  max-width: 250px;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Buttons container (Login & Register) */
.navbar-btns {
  display: flex;
  gap: 1rem;
}

.navbar-btns .btn {
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  transition: 0.3s;
}

.navbar-btns .btn-login {
  border: 2px solid #767268;
  color: #333333;
}

.navbar-btns .btn-login:hover {
  background-color: #f8f8f8;
}

.navbar-btns .btn-masuk {
  background-color: white;
  border: 1px solid black;
  color: black;
  height: 45px;
}

.navbar-btns .btn-register {
  background-color: #FFE74C;
  color: #ffffff;
  height: 45px;
}

.navbar-btns .btn-register:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

@media (max-width: 991px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-search input {
    width: auto;
    max-width: 100%;
  }
}
</style>

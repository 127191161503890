var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "bg-white text-dark pt-5 pb-4"
  }, [_c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-row', {
    staticClass: "text-dark"
  }, [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('b-col', {
    staticClass: "pl-3"
  }, [_c('p', {
    staticClass: "font-weight-light",
    staticStyle: {
      "font-size": "0.95em"
    }
  }, [_vm._v(" " + _vm._s(_vm.appName) + " is an online learning platform that aims to help people develop skills and share knowledge. Our mission extends to making a positive impact on society through charitable donations, including educational scholarships, building places of worship, and other community programs. ")])])], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('h5', {
    staticClass: "font-weight-bold mb-3"
  }, [_vm._v("Kontak")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('feather-icon', {
    attrs: {
      "icon": "PhoneIcon"
    }
  }), _c('span', [_vm._v("087825412805")])], 1)], 1), _c('b-row', [_c('b-col', [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  }), _c('span', [_vm._v("info@sobari.id")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_c('h5', {
    staticClass: "font-weight-bold mb-3"
  }, [_vm._v("Layanan & Informasi")]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-dark",
    attrs: {
      "to": {
        name: 'tentang-kami'
      }
    }
  }, [_vm._v(" Tentang Kami ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-dark",
    attrs: {
      "to": {
        name: 'kebijakan-privasi'
      }
    }
  }, [_vm._v(" Kebijakan Privasi ")])], 1)], 1), _c('b-row', [_c('b-col', [_c('b-link', {
    staticClass: "text-dark",
    attrs: {
      "to": {
        name: 'syarat-ketentuan'
      }
    }
  }, [_vm._v(" Syarat & Ketentuan ")])], 1)], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "bg-white text-center py-3"
  }, [_c('div', {
    staticClass: "container d-flex justify-content-between align-items-center flex-column flex-md-row"
  }, [_c('div', [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("© " + _vm._s(new Date().getFullYear()) + " " + _vm._s(_vm.appName) + " - All rights reserved")])]), _c('div', [_vm._m(0), _c('div', [_c('a', {
    attrs: {
      "href": "https://facebook.com",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "ml-3",
    attrs: {
      "icon": "FacebookIcon",
      "size": "20"
    }
  })], 1), _c('a', {
    attrs: {
      "href": "https://twitter.com",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "ml-3",
    attrs: {
      "icon": "TwitterIcon",
      "size": "20"
    }
  })], 1), _c('a', {
    attrs: {
      "href": "https://www.instagram.com",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "ml-3",
    attrs: {
      "icon": "InstagramIcon",
      "size": "20"
    }
  })], 1), _c('a', {
    attrs: {
      "href": "https://www.youtube.com",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "ml-3",
    attrs: {
      "icon": "YoutubeIcon",
      "size": "20"
    }
  })], 1)])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h6', [_c('strong', [_vm._v("Ikuti Kami")])]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "background"
  }, [_c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "page-header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-8 col-md-7 col-sm-12 text-center text-md-left"
  }, [_c('div', {
    staticClass: "page-caption ml-lg-3"
  }, [_c('p', {
    staticClass: "text-black",
    staticStyle: {
      "font-weight": "bold",
      "font-size": "3rem"
    }
  }, [_vm._v("Temukan Peluang Belajar")]), _c('p', {
    staticClass: "text-black font-weight-bold h4"
  }, [_vm._v("Menarik dan Raih Tujuan Akademik Anda!")])])]), _c('div', {
    staticClass: "col-lg-4 col-md-5 col-sm-12 text-center text-md-right"
  }, [_c('img', {
    staticClass: "header-image img-fluid mx-auto mx-md-0",
    attrs: {
      "src": "https://cdn-web-2.ruangguru.com/landing-pages/assets/hs/LP%20Ruangguru%20Program/banner-right-program.png",
      "alt": "Icon"
    }
  })])])])]), _c('div', {
    staticClass: "card-section"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "padding-left": "5vw",
      "padding-right": "5vw",
      "margin-bottom": "1.5rem"
    }
  }, [_c('div', {
    staticClass: "card-block bg-white rounded border"
  }, [_c('div', {
    staticClass: "section-title mb-3"
  }, [_c('h2', [_vm._v("Paket Paket Pembelajaran")]), _c('p', [_vm._v("Pilih Paket Bimbel & Try Out yang Tepat untuk Kesuksesanmu.")])]), _c('div', {
    staticClass: "py-4"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3"
  }, _vm._l(_vm.data, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "col",
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('div', {
      staticClass: "card mx-auto shadow-lg rounded-3",
      staticStyle: {
        "width": "100%",
        "max-width": "20rem",
        "transition": "0.3s"
      }
    }, [_c('div', {
      staticClass: "position-relative overflow-hidden rounded-top-3"
    }, [_c('img', {
      staticClass: "card-img-top img-fluid",
      staticStyle: {
        "height": "200px",
        "object-fit": "cover"
      },
      attrs: {
        "src": "https://api-ecourse.digma.id/".concat(item.thumbnail),
        "alt": "Thumbnail"
      }
    })]), _c('div', {
      staticClass: "card-body"
    }, [_c('div', {
      staticClass: "d-flex mb-2 font-weight-bold",
      staticStyle: {
        "font-size": "14px"
      }
    }, [_c('span', {
      staticClass: "text-black"
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('i', {
      staticClass: "fa-regular fa-user text-black"
    }), _c('span', {
      staticClass: "text-black ms-1"
    }, [_vm._v("150")]), _c('i', {
      staticClass: "fa-regular fa-clock text-black ml-4"
    }), _c('span', {
      staticClass: "text-black ms-1"
    }, [_vm._v("40m")])]), _c('hr'), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "text-black font-weight-bold"
    }, [_vm._v("Rp " + _vm._s(item.price.toLocaleString('id-ID')))]), _c('span', {
      staticClass: "ml-auto"
    }, [_c('button', {
      staticClass: "btn btn-outline-primary btn-sm",
      attrs: {
        "type": "button"
      }
    }, [_vm._v("Beli")])])])])])]);
  }), 0)])])])])]), _c('footer-home')], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
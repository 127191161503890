<template>
  <section class="bg-white text-dark pt-5 pb-4">
    <b-card no-body class="bg-transparent">
      <div class="container">
        <b-row>
          <!-- About Us Section -->
          <b-col md="6" lg="4" class="mb-4">
            <b-row class="text-dark">
              <b-col cols="3">
                <!-- <b-img :src="img" alt="Logo" rounded width="100px" /> -->
              </b-col>
              <b-col class="pl-3">
                <p class="font-weight-light" style="font-size: 0.95em;">
                  {{ appName }} is an online learning platform that aims to help people develop skills and share knowledge. Our mission extends to making a positive impact on society through charitable donations, including educational scholarships, building places of worship, and other community programs.
                </p>
              </b-col>
            </b-row>
          </b-col>

          <!-- Contact Section -->
          <b-col md="6" lg="3" class="mb-4">
            <h5 class="font-weight-bold mb-3">Kontak</h5>
            <b-row class="mb-2">
              <b-col>
                <feather-icon icon="PhoneIcon" />
                <span>087825412805</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <feather-icon icon="MailIcon" />
                <span>info@sobari.id</span>
              </b-col>
            </b-row>
          </b-col>

          <!-- Services & Information Section -->
          <b-col md="6" lg="2" class="mb-4">
            <h5 class="font-weight-bold mb-3">Layanan & Informasi</h5>
            <b-row class="mb-1">
              <b-col>
                <b-link :to="{ name: 'tentang-kami' }" class="text-dark">
                  Tentang Kami
                </b-link>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <b-link :to="{ name: 'kebijakan-privasi' }" class="text-dark">
                  Kebijakan Privasi
                </b-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-link :to="{ name: 'syarat-ketentuan' }" class="text-dark">
                  Syarat & Ketentuan
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Footer Bottom Section -->
    <div class="bg-white text-center py-3">
      <div class="container d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div>
          <p class="mb-0">© {{ new Date().getFullYear() }} {{ appName }} - All rights reserved</p>
        </div>
        <div>
          <h6><strong>Ikuti Kami</strong></h6>
          <div>
            <a href="https://facebook.com" target="_blank">
              <feather-icon class="ml-3" icon="FacebookIcon" size="20" />
            </a>
            <a href="https://twitter.com" target="_blank">
              <feather-icon class="ml-3" icon="TwitterIcon" size="20" />
            </a>
            <a href="https://www.instagram.com" target="_blank">
              <feather-icon class="ml-3" icon="InstagramIcon" size="20" />
            </a>
            <a href="https://www.youtube.com" target="_blank">
              <feather-icon class="ml-3" icon="YoutubeIcon" size="20" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BLink } from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTable,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BTable,
    BFormGroup,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: ["first_name", "last_name", "age"],
      items: [],
      // img: require("@/assets/images/logo/bsh.svg"),
    };
  },
};
</script>

<style scoped>
*{
  color: black;
}

.bg-white {
  background-color: #fff !important;
}

.text-dark {
  color: #000 !important;
}

.text-center {
  text-align: center;
}

.text-md-left {
  text-align: left;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-bold {
  font-weight: 600;
}

a.text-dark {
  text-decoration: none;
}

a.text-dark:hover {
  text-decoration: underline;
}
</style>
